import { H1, StyledH5 } from "./headings";

import styled from "styled-components";

export const SeriousTalk = styled.div`
    grid-column: col-start / span 12;
    ${props => props.theme.device.tablet} {
      grid-column: 3 / span 8;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 4 / span 6;
    }
    color: #C0C2CC;
    font-size: 1.25em;
    & p, & ul {
        margin-bottom: 2rem;
    }
    & ul {
        margin-left: 2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    & ${StyledH5} {
        font-size: 1.2em;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
`
export const SecondaryPageTitle = styled(H1)`
    grid-column: col-start / span 12;
    ${props => props.theme.device.tablet} {
      grid-column: 3 / span 8;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 4 / span 6;
    }
    text-align: left;
    color: ${props => props.theme.color.textPrimary};
    max-width: 100%;
    margin: 0;
    margin-bottom: ${props => props.dense ? '0' : '32px'};
    font-weight: 800;
    font-size: 3.5em;
`
export const PageMeta = styled.div`
    grid-column: col-start / span 12;
    ${props => props.theme.device.tablet} {
      grid-column: 3 / span 8;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 4 / span 6;
    }
    & a {
        font-size: 1.25rem;
    }
`
